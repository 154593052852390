import React from 'react'
import WebinarVideo from '../components/WebinarVideo'
import Link from '../components/Link'
import acpr from '../assets/images/acpr.png'
import TitleBox from '../components/styledComponents/TitleBox'
import TopContainer from '../components/styledComponents/TopContainer'
import PaddingPaper from '../components/styledComponents/PaddingPaper'
import StyledDivider from '../components/styledComponents/StyledDivider'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import SectionGrid from '../components/styledComponents/SectionGrid'
import Typography from '@mui/material/Typography'

const Webinars = () => {
  return (
    <Box sx={{ position: 'relative' }}>
      <TopContainer>
        <PaddingPaper>
          <TitleBox>
            <Typography component='h2' variant='h3' align='center'>
              NLP in Finance with ACPR
            </Typography>
            <StyledDivider variant='middle' />
          </TitleBox>
          <SectionGrid container>
            <Grid item>
              <Typography variant='body1'>
                On February 11th 2021, we co-organized a webinar with the french
                regulator ACPR about NLP in Finance, welcoming over 150
                participants.
                <br /> You can find the slides of this webinar in English{' '}
                <Link to='/webinar' underline='none' color='secondary'>
                  here
                </Link>
                .
              </Typography>
            </Grid>
            <Grid item align='center'>
              <Box src={acpr} component='img' width='25%' />
            </Grid>
            <Grid item align='center' width='100%'>
              <WebinarVideo />
            </Grid>
          </SectionGrid>
        </PaddingPaper>
      </TopContainer>
    </Box>
  )
}

export default Webinars
