import React, { useState } from 'react'
import webinarThumbnail from '../assets/images/webinar-thumbnail.png'
import Box from '@mui/material/Box'

const WebinarVideo = () => {
  const [thumbnail, showThumbnail] = useState(true)
  return (
    <Box
      align='center'
      sx={{
        aspectRatio: '2/1',
        width: '100%',
        '@media (min-width: 600px)': {
          width: '70%'
        }
      }}
    >
      {thumbnail ? (
        <Box
          src={webinarThumbnail}
          width='100%'
          component='img'
          align='center'
          onClick={() => showThumbnail(false)}
          sx={{ cursor: 'pointer' }}
        />
      ) : (
        <iframe
          height='100%'
          width='100%'
          src='https://www.youtube.com/embed/6_hTrVxgXsg?start=3065&autoplay=1'
          title='YouTube video player'
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
        />
      )}
    </Box>
  )
}

export default WebinarVideo
